import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>ご指定のページが見つかりません。</h1>
  </Layout>
)

export default NotFoundPage
